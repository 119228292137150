<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData"
      :updatable="false"
      :deletable="false"
      :customBtns="_titleBarCustomBtns"
      titleKey="name"
      v-bind="this.$store.state.stone_model.shop_campaign"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
    ></WsReadSection>
    <WsPopupAlert
      ref="campaignDeletePopup"
      :title="$t('確定要刪除此折扣碼嗎？')"
      @submit="$_campaignDelete($event)"
      :submitBtnText="$t('確定')"
    ></WsPopupAlert>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_campaign";
export default {
  metaInfo() {
    return {
      title: this.$t("訂單列表"),
    };
  },
  data() {
    return {
      modelName: model.modelName,
      label: model.label,
      fields: model.fields,
      leftFields: [
        "name",
        "discount_code",
        "duration",
        "full_amount",
        "custom_discount_way",
      ],
      rightFields: [],
      modelData: null,
      showFields: ["type"],
      customTableActions: [
        {
          icon: "icon-ws-outline-eye-open",
          emit: "read",
          tooltip: this.$t("閱讀"),
        },
      ],
    };
  },

  computed: {
    _id() {
      return this.$route.params.id;
    },
    _titleBarCustomBtns() {
      return [
        {
          label: "編輯折扣碼",
          event: "update-campaign",
          icon: {
            left: true,
            // name:,
          },
        },
        {
          label: "刪除折扣碼",
          event: "delete-campaign",
          icon: {
            left: true,
            // name:,
          },
        },
      ];
    },
  },
  methods: {
    async $_titleBarCustomBtnClick($event) {
      if ($event == "update-campaign") {
        this.$router.push(`/shop_campaign/${this._id}/update`);
      }
      if ($event == "delete-campaign") {
        this.$_campaignDeletePopupOpen();
      }
    },
    $_campaignDeletePopupOpen(data) {
      this.$refs.campaignDeletePopup.open(data);
    },
    $_campaignDeletePopupClose() {
      this.$refs.campaignDeletePopup.close();
    },
    async $_campaignDelete() {
      try {
        this.$_campaignDeletePopupClose();
        await this.$axios.delete(`/shop_campaign/${this._id}`);
        await this.$router.push(`/shop_campaign`);
      } catch (err) {
        alert(err);
      }
    },
  },
};
</script>

<style>
</style>